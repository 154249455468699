import React from "react";
import '../styles/Tariffs.css';

function Tariffs() {
    return (
        <div id={"tariffs"}>
            <h3 className="mt-5 text-center">Тарифы</h3>
            <div className="row mt-3">
                <div className="col-md-6 col-lg-4 mb-3">
                    <div className="tariff-card d-flex flex-column card tariff-card-TURBO">
                        <div className="card-content p-3">
                            <div className="tariff-card-header p-3">
                                <b>Ежедневный Turbo тариф</b>
                                <div className="d-flex align-items-center">
                                    <h2 className="tariff-cost">15</h2>

                                    <div className="d-flex flex-column ms-2">
                                        <h6 className="tariff-kzt">₸</h6>
                                        <p className="position-relative tariff-repeat">/ 1 товар / 1 день</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tariff-card-body p-2">
                                <div className="d-flex flex-column justify-content-end h-100">

                                    <p className="tariff-desc mb-3 text-secondary text-center">Гибкость в ваших руках.
                                        Включайте отслеживание для любого товара в любое время и платите только за то,
                                        что используете. Идеально подходит для краткосрочных или временных нужд.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                    <div className="tariff-card d-flex flex-column card tariff-card-ECO">
                        <div className="card-content p-3">
                            <div className="tariff-card-header p-3">
                                <b>Месячный Эконом до 1000 товаров</b>
                                <div className="d-flex align-items-center">
                                    <h2 className="tariff-cost">9,900</h2>

                                    <div className="d-flex flex-column ms-2">
                                        <h6 className="tariff-kzt">₸</h6>
                                        <p className="position-relative tariff-repeat">/ 30 дней</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tariff-card-body p-2">
                                <div className="d-flex flex-column justify-content-end h-100">
                                    <span
                                        className="tariff-desc">Стоимость за месяц за 1 товар составит всего: 9₸</span>
                                    <p className="tariff-desc mb-3 text-secondary text-center">Топ-10 товаров, цены
                                        которых
                                        у конкурентов часто меняются, будут обновляться каждые 5 минут. Все остальные
                                        товары
                                        будут обновляться каждые 15 минут.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                    <div className="tariff-card d-flex flex-column card tariff-card-STANDARD">
                        <div className="card-content p-3">
                            <div className="tariff-card-header p-3">
                                <b>Месячный Стандарт до 1000 товаров</b>
                                <div className="d-flex align-items-center">
                                    <h2 className="tariff-cost">32,000</h2>

                                    <div className="d-flex flex-column ms-2">
                                        <h6 className="tariff-kzt">₸</h6>
                                        <p className="position-relative tariff-repeat">/ 30 дней</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tariff-card-body p-2">
                                <div className="d-flex flex-column justify-content-end h-100">
                                    <span
                                        className="tariff-desc">Стоимость за месяц за 1 товар составит всего: 32₸</span>
                                    <p className="tariff-desc mb-3 text-secondary text-center">Топ-40 товаров, цены
                                        которых у конкурентов часто меняются, будут обновляться каждые 5 минут. Все
                                        остальные товары будут обновляться каждые 10 минут.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                    <div className="tariff-card d-flex flex-column card tariff-card-TURBO">
                        <div className="card-content p-3">
                            <div className="tariff-card-header p-3">
                                <b>Месячный Turbo тариф до 50 товаров</b>
                                <div className="d-flex align-items-center">
                                    <h2 className="tariff-cost">18,000</h2>

                                    <div className="d-flex flex-column ms-2">
                                        <h6 className="tariff-kzt">₸</h6>
                                        <p className="position-relative tariff-repeat">/ 30 дней</p>
                                    </div>
                                </div>
                            </div>
                            <div className="tariff-card-body p-2">
                                <div className="d-flex flex-column justify-content-end h-100">
                                    <span
                                        className="tariff-desc">Стоимость за месяц за 1 товар составит всего: 360₸</span>
                                    <p className="tariff-desc mb-3 text-secondary text-center">Мы гарантируем обновление
                                        цен в течение 2 минут для каждого товара, но на самом деле это происходит еще
                                        быстрее.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                    <div className="tariff-card d-flex flex-column card text-light bg-blue-glowing">
                        <div className="card-content p-3">
                            <div className="tariff-card-header p-3 row">
                                <div className="col-8">
                                    <b>Всего разных</b>
                                    <div className="d-flex align-items-center">
                                        <h2 className="tariff-cost">21</h2>

                                        <div className="d-flex flex-column ms-2">
                                            <h6 className="tariff-kzt">тарифов</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <img className={'why-card-img animate-dance'} src={'/folder.png'}/>
                                </div>
                            </div>
                            <div className="tariff-card-body p-2">
                                <div className="d-flex flex-column justify-content-end h-100">
                                    <div className="d-grid gap-2 m-2">
                                        <a className="btn btn-light" href="https://pricefeed.kz/cabinet/register">Показать все тарифы</a>
                                    </div>
                                    <div className="tariff-desc">Не платите больше, чем нужно — найдите тариф своей
                                        мечты!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tariffs;