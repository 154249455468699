import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import MainBanner from "./components/MainBanner";
import HowItWorksSection from "./components/HowItWorksSection";
import Header from "./components/Header";
import React from "react";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Tariffs from "./components/Tariffs";
import WhyChooseUs from "./components/WhyChooseUs";
import FAQ from "./components/FAQ";
import PromoOffer from "./components/PromoOffer";
import TelegramChannel from "./components/TelegramChannel";

function App() {

    return (
        <div>
            <Header/>
            <main>
                <MainBanner title={"Увеличьте продажи в 3 раза или больше с самым быстрым Каспи ботом!"}
                            text={"Цена: от 450₸ в месяц — выгодно и быстро! Скорость обновление цен до 2 минут!"}
                            link={"https://pricefeed.kz/cabinet/register"}
                            link_text={"Зарегистрируйтесь прямо сейчас и получите 3 дня бесплатно! "}
                            imgSrc={"/main-banner.png"}
                            alt={"PriceFeed - каспи бот настройка"}
                />
                <div className={'info'}>
                    <HowItWorksSection/>
                    <Features/>
                    <WhyChooseUs/>

                    {/*<div className={'row mb-5 mt-5'}>*/}
                    {/*    <div className={'col block d-flex align-items-center'}>*/}
                    {/*        <div className={'py-2 px-1 '}>*/}
                    {/*            <h4>Хотите подключить 2 магазина или более?</h4>*/}
                    {/*            <p className={"mb-3 text-secondary"}>Наши тарифы основаны на количестве товаров, для которых*/}
                    {/*                используется бот, и отключенные товары не учитываются. Вы платите не за каждый магазин*/}
                    {/*                отдельно, а <b>за реально подключенные товары.</b></p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={'col block'}>*/}
                    {/*        <div className={'py-2 px-1'}>*/}
                    {/*            <img src={'/merchant-list.png'} className={'card-img-top'}*/}
                    {/*                 style={{"border-radius": 10}}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className={'row mb-3 mt-4'}>*/}
                    {/*    <div className={'col block d-flex align-items-center'}>*/}
                    {/*        <div className={'py-2 px-1'}>*/}
                    {/*            <h4>Индивидуальная настройка</h4>*/}
                    {/*            <p className={"mb-3 text-secondary"}>Регулируйте алгоритмы и шаги для каждого товара.*/}
                    {/*                Настройте цены для каждого товара вручную или автоматически.</p>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className={'col block'}>*/}
                    {/*        <div className={''}>*/}
                    {/*            <img src={'/product-list.png'} className={'card-img-top'}*/}
                    {/*                 style={{"border-radius": 10}}/>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <Tariffs/>
                    <PromoOffer/>
                    <TelegramChannel/>
                    <FAQ/>
                    <Footer/>
                    <a href="https://wa.me/77087740721?text=Здравствуйте! Хотел(а) бы узнать больше о каспи боте pricefeed.kz и его возможностях." target="_blank" className="whatsapp-btn">
                        <i className="bi bi-whatsapp"></i>
                    </a>
                </div>
            </main>
        </div>
    );
}

export default App;
