import React, { useState, useEffect } from "react";
import "../styles/ExitIntentModal.css";

function ExitIntentModal() {
    const [showModal, setShowModal] = useState(false);
    const promoLink = "https://pricefeed.kz/cabinet/register?code=RQF9J";

    useEffect(() => {
        if (localStorage.getItem("exitModalShown")) {
            return; // Exit if modal has already been shown
        }

        let lastScrollY = window.scrollY;
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY < lastScrollY - 200) {
                setShowModal(true);
            }
            lastScrollY = currentScrollY;
        };

        let inactivityTimer;
        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => setShowModal(true), 30000);
        };

        const handlePopState = () => setShowModal(true);

        const handleMouseLeave = (e) => {
            if (e.clientY <= 0) {
                setShowModal(true);
            }
        };

        window.addEventListener("scroll", handleScroll);
        document.addEventListener("touchmove", resetInactivityTimer);
        document.addEventListener("keydown", resetInactivityTimer);
        document.addEventListener("mousemove", resetInactivityTimer);
        window.addEventListener("popstate", handlePopState);
        document.addEventListener("mouseleave", handleMouseLeave);

        resetInactivityTimer();

        return () => {
            window.removeEventListener("scroll", handleScroll);
            document.removeEventListener("touchmove", resetInactivityTimer);
            document.removeEventListener("keydown", resetInactivityTimer);
            window.removeEventListener("popstate", handlePopState);
            document.removeEventListener("mouseleave", handleMouseLeave);
        };
    }, []);

    useEffect(() => {
        if (showModal) {
            localStorage.setItem("exitModalShown", "true");
        }
    }, [showModal]);

    const handleClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {showModal && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <button className="modal-close-btn" onClick={handleClose}>
                            <i className="bi bi-x-lg"></i>
                        </button>
                        <div className="p-3">
                            <h3>🎁 Не уходите с пустыми руками!</h3>
                            <p>
                                Зарегистрируйтесь прямо сейчас и получите бонус <b>+1000₸</b> на ваш баланс!
                            </p>
                            <a href={promoLink} rel="noopener noreferrer" className="btn btn-primary">
                                Зарегистрироваться и получить бонус
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ExitIntentModal;
