import React from "react";
import '../styles/Features.css';

function Features() {
    return (
        <div>
            <h3 className="mt-4 mb-3 text-center">Дополнительные возможности</h3>
            <div className="row g-2">
                <div className="col-md-8">
                    <div className="row h-100 g-2">
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card bg-white h-100 feature-card-slave">
                                    <h5><i className="color-pink bi bi-safe"></i> Без убытков</h5>
                                    <p> Цена никогда не будет ниже минимальной и
                                        выше максимальной, заданной вами.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card bg-white h-100 feature-card-slave">
                                    <h5><i className="color-pink bi bi-gear"></i> Управление с предзаказами и остатками</h5>
                                    <p>Все изменения, касающиеся остатков и предзаказов,
                                        синхронизируются с вашим магазином на Kaspi.kz, что позволяет избежать ошибок в учёте товаров.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card bg-white h-100 feature-card-slave">
                                    <h5><i className="color-pink bi bi-shop"></i> Поддержка нескольких магазинов</h5>
                                    <p> Один пользователь может управлять несколькими
                                        магазинами.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card bg-white h-100 feature-card-slave">
                                    <h5><i className="color-pink bi bi-eye-slash"></i> Список игнорирования</h5>
                                    <p> Исключайте магазины из конкуренции, если
                                        они принадлежат одному владельцу.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-md-4">
                    <div className="h-100" style={{'position': 'relative'}}>
                        <div className="h-100 bg-blue-glowing" style={{'padding-bottom': 8}}>
                            <div className="feature-card">
                                <div className='row align-items-center'>
                                    <div className='col-8'>
                                        <h5><i className="bi bi-toggles"></i> Алгоритмы</h5>
                                        <ul>
                                            <li><strong>OFF —</strong> отключить
                                                алгоритм.
                                            </li>
                                            <li><strong>Быть первым
                                                —</strong> установите цену (первая цена - шаг).
                                            </li>
                                            <li><strong>Следовать за первым
                                                —</strong> установите цену (первая цена + шаг).
                                            </li>
                                            <li><strong>Быть вторым
                                                —</strong> установите цену (вторая цена - шаг).
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-4'>
                                        <img className={'why-card-img animate-dance'} src={'/settings.png'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Features;
