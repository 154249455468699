import React from "react";
import "../styles/TelegramChannel.css";

function TelegramChannel() {
    return (
        <div id="telegramChannel">
            <h3>
                Будьте в курсе наших акций, обновлений и новостей в Telegram-канале!
            </h3>
            <p>
                Не пропустите эксклюзивные предложения и полезную информацию. Узнайте первыми о новых функциях и выгодных акциях!
            </p>
            <div id="promoLink" className="container">
                <a href="https://t.me/pricefeed_kz" target={'_blank'} className="btn btn-primary">
                    <i className="bi bi-telegram"></i> Присоединиться сейчас <i className="bi bi-arrow-right"></i>
                </a>
            </div>
        </div>
    );
}

export default TelegramChannel;
