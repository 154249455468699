import React from "react";
import '../styles/HowItWorksSection.css';

function HowItWorksSection() {
    return (
        <section className="how-it-works">
            <div className="container text-center">
                <h2 className="mb-2">Как это работает?</h2>
                <p className="text-secondary mb-3">
                    Все просто! Настройка займет всего 1 минут!
                </p>
                <div className="row justify-content-center">
                    <div className="col-md-4 col-sm-6 mb-4">
                        <div className="card border h-100">
                            <div className="card-body">
                                <i className="bi bi-shop-window fs-1 text-danger mb-3"></i>
                                <h5 className="card-title">Шаг 1: Подключите магазин</h5>
                                <p className="card-text text-secondary">
                                    Зарегистрируйтесь и подключите ваш магазин.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                        <div className="card border h-100">
                            <div className="card-body">
                                <i className="bi bi-gear fs-1 text-warning mb-3"></i>
                                <h5 className="card-title">Шаг 2: Настройте товары</h5>
                                <p className="card-text text-secondary">
                                    Укажите минимальные и максимальные цены для товаров.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                        <div className="card border h-100">
                            <div className="card-body">
                                <i className="bi bi-lightning-fill fs-1 text-success mb-3"></i>
                                <h5 className="card-title">Шаг 3: Включите бота</h5>
                                <p className="card-text text-secondary">
                                    Активируйте алгоритм: быть первым.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HowItWorksSection;
