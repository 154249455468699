import React, { useEffect, useState } from "react";
import '../styles/PromoOffer.css';

function PromoOffer() {
    const [timeLeft, setTimeLeft] = useState(null);
    const promoCookieName = "promoExpiration";
    const promoLink = "https://pricefeed.kz/cabinet/register?code=7DJA7";

    useEffect(() => {
        const getCookie = (name) => {
            const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            return match ? match[2] : null;
        };

        const setCookie = (name, value, minutes) => {
            const date = new Date();
            date.setTime(date.getTime() + minutes * 60 * 1000);
            document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
        };

        const calculateTimeLeft = () => {
            const promoExpiration = getCookie(promoCookieName);
            const now = new Date().getTime();

            if (!promoExpiration) {
                // Set promo expiration if not already set
                const expirationTime = now + 10 * 60 * 1000; // 10 minutes from now
                setCookie(promoCookieName, expirationTime, 10);
                return expirationTime - now;
            }

            const remainingTime = promoExpiration - now;
            return remainingTime > 0 ? remainingTime : 0;
        };

        const updateTimer = () => {
            const remaining = calculateTimeLeft();
            setTimeLeft(remaining);
            if (remaining <= 0) {
                document.getElementById("headline").innerText = "Акция завершена!";
            }
        };

        // Initialize timer
        updateTimer();

        // Update every second
        const timer = setInterval(() => {
            updateTimer();
        }, 1000);

        return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    const formatTime = (ms) => {
        const totalSeconds = Math.floor(ms / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return { minutes, seconds };
    };

    const time = timeLeft ? formatTime(timeLeft) : { minutes: 0, seconds: 0 };

    return (
        <div id={"promoOffer"}>
            <h3 className="text-center">🔥 Эксклюзивное предложение только для вас!</h3>
            <div>
                <h1 id="headline">Успей зарегистрироваться за 10 минут</h1>
                <div id="countdown" className="countdown-timer">
                    {timeLeft > 0 ? (
                        <ul className="countdown-list">
                            <li><span id="minutes">{time.minutes}</span> минут</li>
                            <li><span id="seconds">{time.seconds}</span> секунд</li>
                        </ul>
                    ) : (
                        <p>К сожалению, время вышло.</p>
                    )}
                </div>
                {timeLeft > 0 && (
                    <div id="promoLink" className="promo-link">
                        <a href={promoLink} rel="noopener noreferrer" className="btn btn-primary">
                            Зарегистрируйтесь сейчас и получите +1000₸!
                        </a>
                    </div>
                )}
                <div id="content" className="emoji">
                    {timeLeft > 0 ? (
                        <>
                            <span>🥳</span>
                            <span>🎉</span>
                            <span>🎁</span>
                        </>
                    ) : (
                        <span>😔</span>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PromoOffer;
