import React from "react";
import '../styles/WhyChooseUs.css';

function WhyChooseUs() {
    return (
        <div id={'why-choose-us'}>
            <h3 className="mt-5 mb-3 text-center">Почему выбирают нашу систему?</h3>
            <div className="row g-2">
                <div className="col-md-8 ">
                    <div className="row h-100 g-2">
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card feature-card-slave bg-white h-100">
                                    <h5><i className="color-pink bi bi-speedometer2"></i> Самые быстрые обновления</h5>
                                    <p>С нашим Turbo-тарифом мы гарантируем обновления каждые 2 минуты, но на самом деле
                                        это происходит еще быстрее!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card feature-card-slave bg-white h-100">
                                    <h5><i className="color-pink bi bi-grid-1x2"></i> Гибкая система тарифов</h5>
                                    <p>Эконом, Стандарт или Turbo — выберите то, что вам подходит!</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card feature-card-slave bg-white h-100">
                                    <h5><i className="color-pink bi bi-shield-check"></i> Безопасно</h5>
                                    <p>Мы используем только ваш логин и пароль для создания сессии, а не храним их.
                                        Бот имитирует поведение человека.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="h-100">
                                <div className="feature-card feature-card-slave bg-white h-100">
                                    <h5><i className="color-pink bi bi-clock"></i> Экономия времени</h5>
                                    <p>Мы занимаемся вашими ценами, пока вы занимаетесь ростом вашего бизнеса.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4 ">
                    <div className="h-100" style={{'position': 'relative'}}>
                        <div className="h-100 bg-blue-glowing" style={{'padding-bottom': 8}}>
                            <div className="feature-card">
                                <div className='row align-items-center'>
                                    <div className='col-8'>
                                        <h5><i className="bi bi-person-heart"></i> Честность и прозрачность:
                                        </h5>
                                        <div>Мы честно показываем логи бота. Лог — это отчет, где вы можете
                                            увидеть
                                            все действия бота: от отслеживания цен конкурентов до установки новой цены.
                                            Вы
                                            всегда знаете, что бот работает быстро и точно, а его действия легко
                                            проверить
                                            через логи.
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <img className={'why-card-img animate-dance'} src={'/log.png'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;