import React from "react";


function Header() {
    return <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
            <a className="navbar-brand" href="https://pricefeed.kz/cabinet">
                <img alt="Logo" className="header-logo" src="/pricefeed_dark.svg"/>
            </a>

            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <a className="nav-link" href={'#tariffs'}>Тарифы</a>
                    </li>
                </ul>

                <ul className="navbar-nav flex-row flex-wrap ms-md-auto">
                    <li className="nav-item me-2">
                        <a className="nav-link" href="https://pricefeed.kz/cabinet/register">Регистрация</a>
                    </li>
                    <li className="nav-item me-2">
                        <a className="nav-link" href="https://pricefeed.kz/cabinet/sign-in">Вход</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
}

export default Header;