import React from "react";

function Footer() {
    return (
        <div
            className="d-flex flex-wrap justify-content-between align-items-center p-3 border-top">
            <p className="col-md-4 mb-0 text-muted">© 2023, PriceFeed.kz <i className="bi bi-heart-fill text-danger"></i> Махаббатпен
                жасалған! Қазақ тілінде жақында болады.
            </p>

            <ul className="nav col-md-4 justify-content-end">
                <li className="nav-item">
                    <a href="https://pricefeed.kz" className="nav-link px-2 text-muted">Главное</a>
                </li>
                <li className="nav-item"><a href="https://pricefeed.kz/cabinet/register" className="nav-link px-2 text-muted">Начать сейчас</a></li>
                <li className="nav-item"><a href="https://pricefeed.kz/cabinet/register" className="nav-link px-2 text-muted">Тарифы</a></li>
                {/*<li className="nav-item"><a href="#" className="nav-link px-2 text-muted">FAQs</a></li>*/}
                {/*<li className="nav-item"><a href="#" className="nav-link px-2 text-muted">About</a></li>*/}
            </ul>
        </div>
    )
}

export default Footer;