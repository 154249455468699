import React from "react";
import '../styles/FAQ.css';

function FAQ() {
    return (
        <div>
            <h3 className="mt-4 mb-3 text-center">Часто задаваемые вопросы</h3>
            <div className="card mt-3 mb-3">
                <div className="accordion accordion-flush" id="faqAccount">
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading1">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse1" aria-expanded="false"
                                aria-controls="faqAccountCollapse1">
                                Как это работает функция "Автовыход из ямы минимальной цены"?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse1" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading1">
                            <div className="accordion-body">
                                <p>Эта функция позволяет автоматизировать процесс выхода из ситуации, когда текущая цена
                                    удерживает вашу позицию на втором месте из-за достижения минимальной цены, и не
                                    позволяет опустить её ниже.</p>

                                <h4>Как это работает:</h4>
                                <ul>
                                    <li>Если ваша текущая позиция — вторая,</li>
                                    <li>выбранный алгоритм — "Быть первым",</li>
                                    <li>и текущая цена равна минимальной цене,</li>
                                </ul>
                                <p>тогда бот автоматически переключится на алгоритм "Быть вторым" на 30 минут. После
                                    этого бот вернёт стратегию на "Быть первым". Это даёт возможность конкурентам
                                    поднять свои цены, что позволит вам занять первое место, не выходя за пределы
                                    минимальной цены.</p>

                                <p>Эта функция запускается максимум один раз в день для товара, чтобы поддерживать
                                    конкурентное преимущество без нарушения ценовой политики.</p>
                                <p>Вы можете включить или отключить эту функцию в настройках
                                    магазина.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading2">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse2" aria-expanded="false"
                                aria-controls="faqAccountCollapse2">
                                Как можно проверить, как быстро и качественно работает бот?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse2" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading2">
                            <div className="accordion-body">
                                <p>Чтобы убедиться в том, что наш бот работает быстро и качественно, вы можете
                                    ознакомиться с логами работы бота. В логах вы сможете увидеть информацию о
                                    выполненных действиях, изменениях цен и времени реакции бота на изменения
                                    конкурентов. Это поможет вам понять, насколько эффективно наш бот управляет вашими
                                    товарами и реагирует на рыночные изменения.</p>
                                <p>Ссылку на логи можно найти в карточке каждого магазина на странице списка
                                    магазинов.</p>
                                <p>Если у вас возникли вопросы по работе логов или вы хотите узнать больше о процессе,
                                    не стесняйтесь обращаться в нашу службу поддержки.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading3">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse3" aria-expanded="false"
                                aria-controls="faqAccountCollapse3">
                                Как работает автоматическое поднятие цен?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse3" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading3">
                            <div className="accordion-body">
                                <p>Автоматическое поднятие цен включено по умолчанию для всех алгоритмов.</p>
                                <p>Пример работы: допустим, ваша цена — 999 тенге, а цена топ-1 конкурента — 1000 тенге.
                                    Но если конкурент внезапно изменил свою цену на 2000 тенге, бот автоматически
                                    установит вашу цену на 1999 тенге.</p>
                                <p>Важно помнить, что бот не поднимет цену выше вашего максимального порога. Если у вас
                                    задан максимум в 1800 тенге, бот остановится на 1800 тенге.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading4">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse4" aria-expanded="false"
                                aria-controls="faqAccountCollapse4">
                                Есть ли риск продажи товара в убыток?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse4" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading4">
                            <div className="accordion-body">
                                <p>Нет, вы можете установить минимальную цену, ниже которой наш сервис не будет снижать
                                    стоимость товара. Это гарантирует, что вы никогда не уйдёте в минус.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading5">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse5" aria-expanded="false"
                                aria-controls="faqAccountCollapse5">
                                Есть ли возможность работать с предзаказами и изменять их?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse5" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading5">
                            <div className="accordion-body">
                                <p>Да, наш сервис поддерживает работу с предзаказами и их изменение.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading6">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse6" aria-expanded="false"
                                aria-controls="faqAccountCollapse6">
                                Есть ли возможность управлять остатками и изменять их?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse6" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading8">
                            <div className="accordion-body">
                                <p>Да, наш сервис поддерживает управление остатками и их изменение.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading7">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse7" aria-expanded="false"
                                aria-controls="faqAccountCollapse7">
                                Безопасно ли работать с вами?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse7" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading7">
                            <div className="accordion-body">
                                <p>Мы шифруем все пароли и не храним их в открытом виде. Сотрудники и третьи лица не
                                    имеют доступа к этим данным.
                                    Мы также используем защищённое HTTPS-соединение для обеспечения безопасности
                                    передачи данных.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading10">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse10" aria-expanded="false"
                                aria-controls="faqAccountCollapse10">
                                У вас будет доступ к Kaspi Pay?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse10" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading10">
                            <div className="accordion-body">
                                <p>Нет, мы не имеем доступа к Kaspi Pay. Этот сервис защищён с использованием Face ID, и
                                    без биометрической идентификации невозможно авторизоваться.
                                    Мы используем только кабинет продавца, и у нас нет доступа к вашим деньгам.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent border-bottom py-3">
                        <h2 className="accordion-header" id="faqAccountHeading8">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse8" aria-expanded="false"
                                aria-controls="faqAccountCollapse8">
                                Сколько магазинов можно подключить?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse8" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading8">
                            <div className="accordion-body">
                                <p>Вы можете подключить неограниченное количество магазинов к нашей платформе.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item bg-transparent py-3">
                        <h2 className="accordion-header" id="faqAccountHeading9">
                            <button
                                className="accordion-button collapsed bg-transparent shadow-none link-secondary"
                                type="button" data-bs-toggle="collapse"
                                data-bs-target="#faqAccountCollapse9" aria-expanded="false"
                                aria-controls="faqAccountCollapse9">
                                Должен ли я что-то платить, если мне не понравится?
                            </button>
                        </h2>
                        <div id="faqAccountCollapse9" className="accordion-collapse collapse"
                             aria-labelledby="faqAccountHeading9">
                            <div className="accordion-body">
                                <p>У нас есть бесплатный 3-дневный тестовый период. Если в конце этого срока вам не
                                    понравится наш сервис, вы ничего не заплатите. Просто прекратите его использование,
                                    и он перестанет работать для вашего магазина.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FAQ;